<template>
  <base-layout>
    <template v-slot:title>
      <div class="text-lg">
        パスワードをリセットする
      </div>
    </template>
    <div
      class="flex items-center h-full justify-center"
    >
      <div>
        パスワードがリセットされました。 <br />
        <button
          @click="router.replace({ name: 'Login' })"
          class="bg-white shadow"
        >
          ログインに戻る
        </button>
      </div>
    </div>
  </base-layout>
</template>
<script >

import {defineComponent} from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  components: {
  },

  setup() {
    const router = useRouter()
    return {
      router
    }
  }
})
</script>


